import './style.css';
import pipiTextPng from './metadata/pipiText.png';

import meme1img from './metadata/memes/popping/1.jpeg';
import meme2img from './metadata/memes/popping/2.jpeg';
import meme3img from './metadata/memes/popping/3.jpeg';
import meme4img from './metadata/memes/popping/4.jpeg';
import meme5img from './metadata/memes/popping/5.jpeg';
import meme6img from './metadata/memes/popping/6.jpeg';
import meme7img from './metadata/memes/popping/7.jpeg';
import meme8img from './metadata/memes/popping/8.jpeg';
import meme9img from './metadata/memes/popping/9.jpeg';
import meme10img from './metadata/memes/popping/10.jpeg';
import meme11img from './metadata/memes/popping/11.jpeg';
import meme12img from './metadata/memes/popping/12.jpeg';
import meme13img from './metadata/memes/popping/13.jpeg';
import meme14img from './metadata/memes/popping/14.jpeg';
import meme15img from './metadata/memes/popping/15.jpeg';
import meme16img from './metadata/memes/popping/16.jpeg';
import meme17img from './metadata/memes/popping/21.jpeg';
import meme18img from './metadata/memes/popping/18.jpeg';
import meme19img from './metadata/memes/popping/19.jpeg';
import meme20img from './metadata/memes/popping/20.jpeg';
import meme21img from './metadata/memes/popping/17.jpeg';
import meme22img from './metadata/memes/popping/22.jpeg';
import meme23img from './metadata/memes/popping/23.jpeg';
import meme24img from './metadata/memes/popping/24.jpeg';
import meme25img from './metadata/memes/popping/25.jpeg';

const landingPage = document.getElementById('landingPage');
landingPage.style.display = 'block';

const mainPage = document.getElementById('mainPage');
mainPage.style.display = 'none';

const landingContainer = document.createElement('div');

const landingText = document.createElement('span');
landingText.innerHTML = "WANNA ";

const landingSubText = document.createElement('span');
landingSubText.classList.add("cumText");
landingSubText.innerHTML = "CUM?";

landingText.appendChild(landingSubText);
landingContainer.appendChild(landingText)

const closeLandingBtn = document.createElement('button');
closeLandingBtn.innerHTML = "ENTER";
landingContainer.appendChild(closeLandingBtn);
landingPage.appendChild(landingContainer);

function hideLanding() {
  landingPage.style.display = 'none';
  mainPage.style.display = 'block';
}

const header = document.querySelector('header');

const dialogues = document.createElement('span');
dialogues.innerHTML = `PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE
PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE 
PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE PIPI THE PEPE`;
header.appendChild(dialogues);

const main = document.querySelector('main');

const ticker = document.createElement('img');
ticker.id = 'pipiTextPng';
ticker.src = pipiTextPng;
main.appendChild(ticker);

const socialsContainer = document.createElement('div');
socialsContainer.classList.add('socials');

const telegramBtn = document.createElement('button');
telegramBtn.id = "telegram-button";
telegramBtn.innerHTML = `TG`;
socialsContainer.appendChild(telegramBtn);

const twitterBtn = document.createElement('button');
twitterBtn.id = "twitter-button";
twitterBtn.innerHTML = `TWITTER`;
socialsContainer.appendChild(twitterBtn);

const chartBtn = document.createElement('button');
chartBtn.id = "chart-button";
chartBtn.innerHTML = `CHART`;
socialsContainer.appendChild(chartBtn);

const buyBtn = document.createElement('button');
buyBtn.id = "buy-button";
buyBtn.innerHTML = `BUY`;
socialsContainer.appendChild(buyBtn);

main.appendChild(socialsContainer);

const footer = document.querySelector('footer');

const contract = document.createElement('span');
contract.id = 'contract';
contract.innerHTML = "8QZh4Z2quPzHDXtZNkexg9LfTiDh1MfYCLTcxNGzpump";
footer.appendChild(contract);

const copyContractBtn = document.createElement('button');
copyContractBtn.id = 'copyContractBtn';
copyContractBtn.innerHTML = "Copy Contract";
footer.appendChild(copyContractBtn);

const memeImgArray = [meme1img, meme2img, meme3img, meme4img, meme5img, meme6img, meme7img, meme8img, meme9img, meme10img, meme11img, meme12img, meme13img, meme14img, meme15img, meme16img, meme17img, meme18img, meme19img, meme20img, meme21img, meme22img, meme23img, meme24img, meme25img];

function backdropStatic() {
  const backdropStatic = document.getElementById('backdrop-static');

  memeImgArray.forEach(img => {
    const container = document.createElement('img');
    container.src = img; 
    backdropStatic.appendChild(container);
  });
}
backdropStatic();



const backdropAnimation = document.getElementById('backdrop-animation');
const meme1 = document.createElement('img');
meme1.src = meme1img;
backdropAnimation.appendChild(meme1);

const meme2 = document.createElement('img');
meme2.src = meme2img;
backdropAnimation.appendChild(meme2);

const meme3 = document.createElement('img');
meme3.src = meme3img;
backdropAnimation.appendChild(meme3);

const meme4 = document.createElement('img');
meme4.src = meme4img;
backdropAnimation.appendChild(meme4);

const meme5 = document.createElement('img');
meme5.src = meme5img;
backdropAnimation.appendChild(meme5);

const meme6 = document.createElement('img');
meme6.src = meme6img;
backdropAnimation.appendChild(meme6);

const meme7 = document.createElement('img');
meme7.src = meme7img;
backdropAnimation.appendChild(meme7);

const meme8 = document.createElement('img');
meme8.src = meme8img;
backdropAnimation.appendChild(meme8);

const meme9 = document.createElement('img');
meme9.src = meme9img;
backdropAnimation.appendChild(meme9);

const meme10 = document.createElement('img');
meme10.src = meme10img;
backdropAnimation.appendChild(meme10);

const meme11 = document.createElement('img');
meme11.src = meme11img;
backdropAnimation.appendChild(meme11);

const meme12 = document.createElement('img');
meme12.src = meme12img;
backdropAnimation.appendChild(meme12);

const meme13 = document.createElement('img');
meme13.src = meme13img;
backdropAnimation.appendChild(meme13);

const meme14 = document.createElement('img');
meme14.src = meme14img;
backdropAnimation.appendChild(meme14);

const meme15 = document.createElement('img');
meme15.src = meme15img;
backdropAnimation.appendChild(meme15);

const meme16 = document.createElement('img');
meme16.src = meme16img;
backdropAnimation.appendChild(meme16);

const meme17 = document.createElement('img');
meme17.src = meme17img;
backdropAnimation.appendChild(meme17);

const meme18 = document.createElement('img');
meme18.src = meme18img;
backdropAnimation.appendChild(meme18);

const meme19 = document.createElement('img');
meme19.src = meme19img;
backdropAnimation.appendChild(meme19);

const meme20 = document.createElement('img');
meme20.src = meme20img;
backdropAnimation.appendChild(meme20);

const meme21 = document.createElement('img');
meme21.src = meme21img;
backdropAnimation.appendChild(meme21);

const meme22 = document.createElement('img');
meme22.src = meme22img;
backdropAnimation.appendChild(meme22);

const meme23 = document.createElement('img');
meme23.src = meme23img;
backdropAnimation.appendChild(meme23);

const meme24 = document.createElement('img');
meme24.src = meme24img;
backdropAnimation.appendChild(meme24);

const meme25 = document.createElement('img');
meme25.src = meme25img;
backdropAnimation.appendChild(meme25);


document.addEventListener('DOMContentLoaded', function() {
  closeLandingBtn.addEventListener('click', hideLanding);

  // LINKS
  twitterBtn.addEventListener('click', function() {
    window.open('https://x.com/pipithepepe', '_blank');
  });

  telegramBtn.addEventListener('click', function() {
    window.open('https://t.me/pipiportalsol', '_blank');
  });

  chartBtn.addEventListener('click', function() {
    window.open('https://dexscreener.com/solana/mdwdob9nirmevo26oa1ki7wwmkpgqfppz5ysewwksqj', '_blank');
  });

  buyBtn.addEventListener('click', function() {
    window.open('https://raydium.io/swap/?outputMint=8QZh4Z2quPzHDXtZNkexg9LfTiDh1MfYCLTcxNGzpump&inputMint=sol', '_blank');
  });

  // COPY FUNCTION
  const copyButton = copyContractBtn;
  const textToCopy = "8QZh4Z2quPzHDXtZNkexg9LfTiDh1MfYCLTcxNGzpump";
  copyButton.addEventListener("click", async () => {
    try {
      // Try the modern Clipboard API first (if supported)
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied successfully using Clipboard API");
      alert("Contract copied successfully!");
    } catch (err) {
      // If Clipboard API fails, use the legacy approach
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed"; // Hide element off-screen
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      console.log("Text copied successfully using legacy approach");
      alert("Contract copied successfully!");
    }
  });

  const memesToPop = [meme1, meme2, meme3, meme4, meme5, meme6, meme7, meme8, meme9, meme10, meme11, meme12, meme13, meme14, meme15, meme16, meme17, meme18, meme19, meme20, meme21, meme22, meme23, meme24, meme25];
  let currentIndex = null;
  function getRandomIndex() {
    return Math.floor(Math.random() * memesToPop.length);
  }
  function showRandomMeme() {
    if (currentIndex !== null) {
      memesToPop[currentIndex].style.opacity = '0.0';
    }

    let newIndex = getRandomIndex();

    while (newIndex === currentIndex) {
      newIndex = getRandomIndex();
    }
    
    memesToPop[newIndex].style.opacity = '1.0';
    currentIndex = newIndex;
  }

  // Initially hide all memes
  memesToPop.forEach(meme => {
    meme.style.opacity = '0.0';
  });
  // Show a random meme every 1/3 second
  setInterval(showRandomMeme, 333);
});